import { useIsMobile } from "@/libs/hooks/useIsMobile";
import { useMainDict } from "@/libs/hooks/useTranslation";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconMapPin,
  IconNavigation,
  IconPhone,
  IconPhoneCall,
} from "@tabler/icons-react";
import { OpeningHoursModal } from "../OpeningHoursModal";
import { useSettings } from "@/libs/hooks/useSettings";

export type AddressAndPhoneProps = {
  phoneNumber: string | undefined;
  address: string | undefined;
  showOpeningHours: boolean | undefined;
};

export const AddressAndPhone: React.FC<AddressAndPhoneProps> = ({
  phoneNumber,
  address,
  showOpeningHours,
}) => {
  const { t } = useMainDict();
  const isMobile = useIsMobile();
  const { settings } = useSettings();
  return (
    <>
      <div className="text-center font-bold text-xl mt-6 px-1 text-pizza-papaya flex flex-col md:flex-row md:flex-wrap items-center w-full justify-center align-center">
        <a
          href="#menu-section"
          type="button"
          aria-label={t("showMenu")}
          className="w-full uppercase md:w-auto flex items-center justify-center transition-all ease-in duration-75 active:shadow-inner bg-pizza-yellow-darker dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75  px-5 py-2.5 text-center mr-2 mb-3"
        >
          {t("showMenu")}
        </a>

        <div className="flex w-full md:w-auto justify-center">
          {isMobile && (
            <a
              target="_blank"
              type="button"
              href={`tel:${phoneNumber?.replaceAll(" ", "")}`}
              aria-label={t("callUs")}
              className="w-full uppercase md:w-auto flex items-center justify-center transition-all ease-in duration-75 bg-pizza-pistachio dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-90 text-pizza-papaya  active:shadow-inner  px-5 py-2.5 text-center mr-2 mb-3"
            >
              <IconPhoneCall className="mr-3" aria-hidden="true" />
              {t("callUs")}
            </a>
          )}
          <a
            target="_blank"
            href="https://www.google.com/maps/dir/?api=1&destination=Pizzicletta,Kalwaryjska 3,30-509 Kraków"
            aria-label={t("navigateToUs")}
            className="w-full uppercase md:w-auto flex items-center justify-center transition-all ease-in duration-75 active:shadow-inner bg-pizza-red dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75 px-5 py-2.5  text-center mr-2 mb-3"
          >
            <IconNavigation className="mr-3 animate-pulse" aria-hidden="true" />
            {t("navigateToUs")}
          </a>
        </div>
        {showOpeningHours && <OpeningHoursModal />}
      </div>
      <div className="text-center font-bold text-xl mt-2 text-pizza-papaya flex flex-col items-center w-full">
        <div className="flex gap-3 items-center justify-center">
          <IconPhone aria-hidden="true" /> {phoneNumber}
        </div>
        <div className="inline-flex gap-3 items-top justify-around ">
          <IconMapPin aria-hidden="true" />
          <p className="whitespace-nowrap"> {address}</p>
        </div>
      </div>
      <div className="text-center font-bold text-5xl mt-4 text-pizza-papaya flex gap-2 items-center w-full justify-center">
        {settings?.instagramLink && (
          <a
            target="_blank"
            href={settings?.instagramLink}
            aria-label="Instagram"
          >
            <IconBrandInstagram size={40} aria-hidden="true" />
          </a>
        )}
        {settings?.facebookLink && (
          <a
            target="_blank"
            href={settings?.facebookLink}
            aria-label="Facebook"
          >
            <IconBrandFacebook size={40} aria-hidden="true" />
          </a>
        )}
        {settings?.tiktokLink && (
          <a target="_blank" href={settings?.tiktokLink} aria-label="TikTok">
            <IconBrandTiktok size={40} aria-hidden="true" />
          </a>
        )}
      </div>
    </>
  );
};
