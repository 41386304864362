
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Footer } from "@/+components/Footer";
import LanguageFlags from "@/+components/LanguageFlags";
import MainBannerComponent from "@/+components/MainBanner/MainBanner";
import { WelcomeSectionComponent } from "@/+components/WelcomeSection";
import { MenuSection } from "@/+components/Menu/MenuSection";
import { IngredientsSection } from "@/+components/Ingredients/IngredientsSection";
import { FindUsAndMapSection } from "@/+components/FindUsAndMapSection";
import { FollowOnInstagramSection } from "@/+components/FollowOnInstagramSection";
import ScrollToTopButton from "@/+components/ScrollToTop";
import { preFetchSettings } from "@/libs/hooks/useSettings";
import { preFetchMenu } from "@/libs/hooks/useMenu";
import { preFetchIngredients } from "@/libs/hooks/useIngredients";
import { GetStaticProps } from "next";
import { PromotionsSection } from "@/+components/Promotions";
import { preFetchOpeningHours } from "@/libs/hooks/useOpeningHours";

export default function Home() {
  return (
    <>
      <MainBannerComponent />
      <WelcomeSectionComponent />
      <MenuSection />
      <IngredientsSection />
      <PromotionsSection
        backButton={false}
        openPolicy={false}
        showLinks={false}
      />
      <FindUsAndMapSection />
      <FollowOnInstagramSection />
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

 const _getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;
  const settings = await preFetchSettings();
  const menu = await preFetchMenu();
  const ingredients = await preFetchIngredients(locale || "en");
  const openingHours = await preFetchOpeningHours(locale || "en");

  const fallback = {
    ...settings,
    ...menu,
    ...ingredients,
    ...openingHours,
  };

  return {
    props: {
      fallback,
    },
  };
};


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  