import useSWR from "swr";
import { appConfig } from "../appConfig";
import { fetcher } from "../fetcher";

export type SettingsResponse = {
  data: {
    id: number;
    attributes: {
      isOpen: boolean | null;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      showMapLocation: boolean;
      showGlovo: boolean;
      showPyszne: boolean;
      showWolt: boolean;
      showMenu: boolean;
      showIngredients: boolean;
      showBolt: boolean;
      showUber: boolean;
      showOurStoryLink: boolean;
      emailAddress: string;
      phoneNumber: string;
      pdfMenuLink: string;
      showDownloadMenuLink: boolean;
      showOpeningHoursButton: boolean;
      address: string;
      instagramLink: string;
      facebookLink: string;
      tiktokLink: string;
      uberLink: string;
      glovoLink: string;
      woltLink: string;
      boltLink: string;
      pyszneLink: string;
    };
  };
  meta: {};
};

const SettingsApiRoute = `/setting`;

export async function preFetchSettings() {
  try {
    const settingsData = await fetcher<SettingsResponse>(SettingsApiRoute);

    return { [SettingsApiRoute]: settingsData };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function useSettings() {
  const { data, isLoading } = useSWR<SettingsResponse | SettingsResponse>(
    SettingsApiRoute,
    { refreshInterval: 0 }
  );

  return { settings: data?.data?.attributes, isLoading };
}
