import { useSettings } from "@/libs/hooks/useSettings";
import { useMainDict } from "@/libs/hooks/useTranslation";
import Image from "next/image";

export const DeliveryLinksComponent = () => {
  const { settings } = useSettings();
  const { t } = useMainDict();
  return (
    <>
      <h1 className="w-full mt-3 font-extrabold text-pizza-yellow md:text-[3.5rem] text-5xl text-center uppercase">
        {t("orderNow")}
      </h1>
      <div className="sm:inline-flex sm:flex-row gap-2 mt-6 flex flex-col w-full justify-center">
        {settings?.showWolt && settings.woltLink && (
          <a
            target="_blank"
            type="button"
            aria-label="Order on Wolt"
            href={settings.woltLink}
            className="cursor-pointer active:shadow-inner  flex items-center justify-center  p-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75"
          >
            <Image src="/wolt.png" height={45} width={65} alt="Order on Wolt" />
          </a>
        )}
        {settings?.showGlovo && settings.glovoLink && (
          <a
            target="_blank"
            type="button"
            aria-label="Order on Glovo"
            href={settings.glovoLink}
            className="cursor-pointer active:shadow-inner  flex items-center justify-center  p-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75"
          >
            <Image
              src="/glovo.png"
              height={45}
              width={90}
              alt="Order on Glovo"
            />
          </a>
        )}
        {settings?.showBolt && settings.boltLink && (
          <a
            target="_blank"
            type="button"
            aria-label="Order on Bolt"
            href={settings.boltLink}
            className=" cursor-pointer active:shadow-inner  flex items-center justify-center p-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75"
          >
            <Image
              src="/bolt.png"
              height={65}
              width={100}
              alt="Order on Bolt"
            />
          </a>
        )}
        {settings?.showUber && settings.uberLink && (
          <a
            target="_blank"
            type="button"
            aria-label="Order on Uber Eats"
            href={settings.uberLink}
            className="cursor-pointer active:shadow-inner  flex items-center justify-center p-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75"
          >
            <Image
              src="/uber.png"
              height={65}
              width={100}
              alt="Order on Uber Eats"
            />
          </a>
        )}
        {settings?.showPyszne && settings.pyszneLink && (
          <a
            target="_blank"
            type="button"
            aria-label="Order on Pyszne"
            href={settings.pyszneLink}
            className="cursor-pointer  active:shadow-inner  flex items-center justify-center p-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full hover:bg-opacity-100 bg-opacity-75"
          >
            <Image
              src="/pyszne.png"
              height={65}
              width={100}
              alt="Order on Pyszne"
            />
          </a>
        )}
      </div>
    </>
  );
};
