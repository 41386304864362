import useSWR from "swr";

import { fetcher } from "../fetcher";
import { BlocksContent } from "@strapi/blocks-react-renderer";
import { useMainDict } from "./useTranslation";

export type SettingsResponse = {
  data: {
    id: number;
    attributes: {
      welcomeCopy: BlocksContent;
      wypadnijNaPlacekCopy: string;
      obserwujNasNaInstaCopy: string;
      bannerCopy: string;
    };
  };
  meta: {};
};

const getApiRoute = (language: string) => `/copy?locale=${language}`;

export async function preFetchCopies(language: string) {
  try {
    const apiRoute = getApiRoute(language);

    const copiesData = await fetcher<SettingsResponse>(apiRoute);

    return { [apiRoute]: copiesData };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function useCopies() {
  const { language } = useMainDict();

  const { data, isLoading } = useSWR<SettingsResponse | SettingsResponse>(
    getApiRoute(language),
    { refreshInterval: 0 }
  );

  return { copy: data?.data?.attributes, isLoading };
}
