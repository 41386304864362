import { useMainDict } from "@/libs/hooks/useTranslation";
import Image from "next/image";
import { DeliveryLinksComponent } from "./DeliveryLinks";
import { AddressAndPhone } from "./AddressAndPhone";
import { appConfig } from "@/libs/appConfig";
import { TermsAndPoliciesLinks } from "../TermsAndPoliciesLinks";
import { useSettings } from "@/libs/hooks/useSettings";
import { useCopies } from "@/libs/hooks/useCopy";

export const MainBannerComponent = () => {
  const { t } = useMainDict();
  const { settings } = useSettings();

  const { copy } = useCopies();

  return (
    <section
      className=" h-auto bg-pizza-blue w-full flex justify-center p-5 overflow-x-clip"
      aria-labelledby="main-banner-heading"
    >
      <div className="max-w-7xl flex flex-col w-full">
        <div className="flex items-center justify-center ">
          <Image
            src={"/newest.png"}
            width={125}
            height={125}
            alt="Brand logo"
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-3 h-full">
          <div className="flex justify-center items-center flex-col">
            <div className="text-shadow-md">
              <h1
                id="main-banner-heading"
                className="text-left w-full font-extrabold text-pizza-papaya md:text-[5rem] text-6xl "
              >
                {t("mainBanner.its")}
              </h1>
              <h1 className="text-left w-full font-extrabold text-pizza-papaya md:text-[5rem] text-6xl  whitespace-nowrap">
                {t("mainBanner.pizzaTime")}
              </h1>
            </div>
            <AddressAndPhone
              phoneNumber={settings?.phoneNumber}
              address={settings?.address}
              showOpeningHours={settings?.showOpeningHoursButton}
            />
            <DeliveryLinksComponent />
            <TermsAndPoliciesLinks showPrivacy={false} showPromotion={false} />
          </div>

          <div className="lg:m-auto flex flex-1 justify-center items-center">
            <Image
              unoptimized={true}
              alt="Delicious margherita pizza"
              src={`${appConfig.assetsUrl}/uploads/margherita_adf472eb14.png`}
              width={600}
              height={600}
              className="w-auto lg:w-[600px] drop-shadow-2xl"
              priority
            />
          </div>
        </div>
        <h2 className="mt-10 z-40 font-hand flex-1 text-center crazy-font md:text-[4.5rem] lg:whitespace-nowrap md:crazy-font-move-up">
          {copy?.bannerCopy}
        </h2>
      </div>
    </section>
  );
};

export default MainBannerComponent;
