import useGAnalytics, {
  EventAction,
  EventCategory,
} from "@/libs/hooks/tracking/useGAnalytics";
import useVisibilityTracker from "@/libs/hooks/tracking/useVisibilityTracking";
import { useCopies } from "@/libs/hooks/useCopy";
import { useMainDict } from "@/libs/hooks/useTranslation";

export const FindUsAndMapSection = () => {
  const { t } = useMainDict();

  const { trackEvent } = useGAnalytics();

  const { copy } = useCopies();

  useVisibilityTracker("map-section", () => {
    trackEvent(
      "map-section-visible",
      EventCategory.USER_INTERACTION,
      EventAction.BECOMES_VISIBLE
    );
  });

  const handleMapClick = () => {
    trackEvent(
      "map-clicked",
      EventCategory.USER_INTERACTION,
      EventAction.CLICK,
      "Map"
    );
  };

  return (
    <>
      <section
        id="map-section"
        className="bg-pizza-yellow-darker flex justify-center items-center text-pizza-papaya"
        aria-labelledby="find-us-heading"
      >
        <div className="py-28 px-2  lg:max-w-7xl">
          <p
            id="find-us-heading"
            className="text-center text-4xl md:text-7xl font-hand text-shadow-md"
          >
            {copy?.wypadnijNaPlacekCopy}
          </p>
        </div>
      </section>
      <section aria-labelledby="map-heading">
        <h2 id="map-heading" className="sr-only">
          {t("mapSectionTitle")}
        </h2>
        <div style={{ width: "100%" }}>
          <iframe
            className="w-full md:h-[500px] h-screen"
            id="gmap_canvas"
            title={t("mapTitle")}
            frameBorder="0"
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
            onClick={handleMapClick}
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Pizzicletta,%20Krakow+(Pizzicletta)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </section>
    </>
  );
};
