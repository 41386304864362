import { useSettings } from "@/libs/hooks/useSettings";
import { useMainDict } from "@/libs/hooks/useTranslation";
import { Menu } from "./Menu";
import { useMenu } from "@/libs/hooks/useMenu";
import useTranslation from "next-translate/useTranslation";
import useGAnalytics, {
  EventAction,
  EventCategory,
  EventLabel,
} from "@/libs/hooks/tracking/useGAnalytics";
import useVisibilityTracker from "@/libs/hooks/tracking/useVisibilityTracking";

export const MenuSection = () => {
  const { settings } = useSettings();
  const { t } = useMainDict();
  const { menu, isMenuLoading } = useMenu();
  const { lang } = useTranslation();

  const { trackEvent } = useGAnalytics();

  useVisibilityTracker("menu-section", () => {
    trackEvent(
      "menu-section-visible",
      EventCategory.USER_INTERACTION,
      EventAction.BECOMES_VISIBLE,
      EventLabel.Menu
    );
  });

  if (!settings?.showIngredients) {
    return null;
  }

  return (
    <section
      id="menu-section"
      aria-labelledby="menu-heading"
      className="bg-pizza-pistachio w-full justify-center flex lg:flex-row flex-col px-2"
    >
      <div className="flex-1 lg:justify-start lg:max-w-7xl justify-center flex flex-col">
        <div className="alg:min-h-full min-h-72 text-pizza-papaya h-full flex align-center flex-col justify-center p-4">
          <h2
            id="menu-heading"
            className="font-futura text-6xl font-bold text-shadow-md text-center my-6"
          >
            {t("menuTitle")}
          </h2>
          {settings.showDownloadMenuLink && settings.pdfMenuLink && (
            <div className="flex flex-row justify-center">
              <a
                type="button"
                target="_blank"
                href={settings.pdfMenuLink}
                className="text-pizza-papaya  w-auto uppercase
               bg-pizza-yellow-darker font-bold outline-1
                outline-gray-50 active:shadow-inner rounded-full
                 text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-opacity-85 hover:bg-opacity-100"
                aria-label={t("downloadMenu")}
              >
                <p className="animate animate-pulse">{t("downloadMenu")}</p>
              </a>
            </div>
          )}

          <Menu lang={lang} menu={menu} isLoading={isMenuLoading} />
        </div>
      </div>
    </section>
  );
};
